import useModalHook from "@lib/modals";
import CloseButton from "@src/components/elements/CloseButton";
import useFormHandlers from "@src/components/elements/Forms/hooks/useFormHandlers";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import PhoneFieldInput from "@src/components/elements/Forms/components/PhoneFieldInput";
import HTMLRender from "@src/components/core/HTMLRender";
import { useEffect, useState } from "react";
import ErrorLabel from "@src/components/elements/Forms/components/ErrorLabel";
import { getCookie } from "cookies-next";
import Button from "@src/components/elements/Button";
import { useStore } from "@lib/store";

YupPassword(Yup);

const ModalReferAFriend = ({ context }) => {
  const { modals } = context;
  const [submitResponse, setSubmitResponse] = useState(null);
  const modal = useModalHook();
  const props = modals && modals.length > 0 ? modals[0].props : undefined;
  const handler = useFormHandlers();
  const { gv } = useGlobalVariable();
  const currentProductType = useStore((state) => state.currentProductType);

  // useEffect(() => {
  //   if (submitResponse && submitResponse.statusCode === "400") {
  //     setTimeout(() => {
  //       setSubmitResponse(null);
  //     }, 8000);
  //   }
  // }, [submitResponse]);
  if (!props) return null;

  const initialValues = {
    email: "",
    name: "",
    phone_number: "",
    referred_email: "",
    agree_refer: false,
  };

  const ReferAFriendValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(<ErrorLabel err="forms_errors_email_required" />)
      .required(<ErrorLabel err="forms_errors_email_required" />),
    name: Yup.string()
      .required(<ErrorLabel err="forms_errors_firstname_required" />)
      .matches(/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/, {
        message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
        when: (value) => !/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/.test(value),
      })
      .min(2, <ErrorLabel err="forms_errors_rule_min_length" value="2" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    phone_number: Yup.string().required(<ErrorLabel err="forms_errors_phone_required" />),
    referred_email: Yup.string()
      .email(<ErrorLabel err="forms_errors_email_required" />)
      .required(<ErrorLabel err="forms_errors_email_required" />),
    agree_refer:
      currentProductType?.attributes?.risk_warning_type === "adgm"
        ? Yup.boolean()
            .oneOf([true], <ErrorLabel err="forms_errors_field_required" />)
            .required(<ErrorLabel err="forms_errors_field_required" />)
        : Yup.boolean().notRequired(),
  });
  const currentUserCountry = getCookie("regulation_country");

  const formResponse =
    submitResponse && submitResponse.statusCode === 200 ? (
      <div className="center overflow-hidden p-md lg:p-10">
        <HTMLRender data={gv("forms_response_refer_a_friend_success")} />
      </div>
    ) : (
      <div className="center overflow-hidden p-md lg:p-10">
        <HTMLRender data={gv("forms_response_errors_something_went_wrong")} />
      </div>
    );

  return (
    <div className="rounded-xl bg-white md:w-[500px]">
      <CloseButton onClick={() => modal.openCloseAll()} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={ReferAFriendValidationSchema}
        onSubmit={async (values) => {
          const response = await handler.onSubmitReferAFriend(values).catch((err) => {});
          if (response?.statusCode === 200) {
            setSubmitResponse({
              ...response,
              statusCode: 200,
            });
          } else {
            setSubmitResponse({
              statusCode: 400,
            });
          }
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting, validate, submitCount }) =>
          !submitResponse ? (
            <form onSubmit={handleSubmit} className="rounded-xl bg-white px-md py-md  md:px-lg md:py-xl">
              <div>
                <div
                  name="schedule"
                  id="schedule"
                  encType="application/json"
                  method="post"
                  noValidate="novalidate"
                  className="justiy-center flex flex-col flex-wrap md:flex-row  md:justify-between"
                >
                  <h3 className="mb-lg w-full text-center text-dark md:mb-xl">
                    {gv("forms_labels_refer_a_friend_title")}
                  </h3>
                  <h5 className="mb-md text-lg">{gv("forms_labels_refer_a_friend_referrer_title")} </h5>
                  <div className="margin-0 relative w-full pb-md">
                    <Field type="text" name="email" placeholder={gv("email_address")} />
                    {errors?.email && submitCount > 0 && errors?.email}
                  </div>
                  <h5 className="mb-md text-lg">{gv("forms_labels_refer_a_friend_referred_title")} </h5>
                  <div className="margin-0 relative w-full pb-md">
                    <Field type="text" name="name" placeholder={gv("first_name")} />
                    {errors?.name && submitCount > 0 && errors?.name}
                  </div>
                  <div className="margin-0 relative w-full pb-md">
                    {/* Phone Number */}
                    <div className="phone-field-wrapper">
                      <PhoneFieldInput
                        currentUserCountry={currentUserCountry.toUpperCase()}
                        label="Phone Number"
                        name="phone_number"
                        type="tel"
                        placeholder={gv("phone")}
                      />
                    </div>
                    {errors?.phone_number && submitCount > 0 && errors?.phone_number}
                  </div>
                  <div className="margin-0 relative w-full pb-md">
                    <Field type="email" name="referred_email" placeholder={gv("email_address")} />
                    {errors?.referred_email && submitCount > 0 && errors?.referred_email}
                  </div>
                  {currentProductType?.attributes?.risk_warning_type === "adgm" && (
                    <>
                      <div className="margin-0 pb-m relative flex w-full">
                        <Field
                          type="checkbox"
                          name="agree_refer"
                          id="agree_refer"
                          placeholder="Terms and Conditions apply"
                          className="mt-[0.3rem] cursor-pointer"
                        />
                        <label className="cursor-pointer" htmlFor="agree_refer">
                          <HTMLRender data={gv("form_agree_refer")} />
                        </label>
                      </div>
                      <div>{errors?.agree_refer}</div>
                    </>
                  )}

                  <div className="margin-0 relative w-full pt-md">
                    <div className="button-wrapper">
                      <Button
                        style={{
                          backgroundColor: "default",
                        }}
                        data={{
                          submit: true,
                          label: gv("forms_field_name_button_refer_a_friend"),
                          disabled: isSubmitting,
                          onClick: handleSubmit,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            formResponse
          )
        }
      </Formik>
    </div>
  );
};
export default ModalReferAFriend;
