import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";

export default function ErrorLabel({ err, value = false }) {
  const { gv } = useGlobalVariable();
  if (value) {
    return (
      <p className="p-1 text-xs text-[red]">
        {gv(err, {
          template: {
            value,
          },
        })}
      </p>
    );
  }
  return <p className="p-1 text-xs text-[red]">{gv(err)}</p>;
}
