import { MantineProvider } from "@mantine/core";
import ModalProviderConfig from "./modalsProviderConfig";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { SWRConfig } from "swr";
import SWRGlobalRequests from "./SwrGlobalRequests";
import { shallow } from "zustand/shallow";
import useCookie from "./helpers/useCookie";
import { useStore } from "@lib/store";

export default function ApiProviders({ globalData, children, fallbackSwr, }) {
  useCookie();

  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );

  return (
    <SWRConfig
      value={{
        provider: () => new Map(),
        fallback: {
          // "api/globaldata?locale=en": globalData,
          [`${process.env.NEXT_PUBLIC_API_STRAPI}/api/pages/layout-shared-data?locale=${globalData?.locale}&build_id=${process.env.NEXT_PUBLIC_BUILD_ID}`]: fallbackSwr?.layoutSharedData,
        },
        refreshInterval: 60 * 30 * 1000,
      }}
    >
      <SWRGlobalRequests />
      <MantineProvider
        theme={{
          dir: store ? store.dir : "ltr",
          fontFamily: store.dir === "ltr" ? "'Source Sans Pro', ui-sans-serif, system-ui" : "'Cairo', 'ui-sans-serif', 'system-ui'"
        }}
        defaultProps={{
          Modal: { size: "xl" },
        }}
      >
        <NotificationsProvider limit={1}>
          <ModalsProvider
            modals={ModalProviderConfig}
            modalProps={{
              withCloseButton: false,
              centered: true,
              styles: {
                modal: {
                  width: "auto",
                  "max-width": "750px",
                  "background-color": "transparent",
                },
              },
            }}
          >
            {children}
          </ModalsProvider>
        </NotificationsProvider>
      </MantineProvider>
    </SWRConfig>
  );
}
