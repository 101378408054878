import { useEffect } from "react";
import { setCookie } from "nookies";
import { shallow } from "zustand/shallow";
import { useStore } from "@lib/store";

export default function useCookie() {
  const store = useStore(
    (store) => ({
      locale: store.locale,
      product_type: store.product_type,
      slug: store.slug,
      languages: store.languages,
    }),
    shallow
  );

  useEffect(() => {
    const cookieData = {
      locale: store.locale,
      product_type: store.product_type,
      company_id: store?.languages?.company_id,
    };
    setCookie(null, "locale:product_type", JSON.stringify(cookieData), {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  }, [store.slug]);
}
