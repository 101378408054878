import { useEffect } from "react";
import { useRouter } from "next/router";
import { useStore } from "@lib/store";
import get from 'lodash/get';

const useLocaleChange = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (destination) => {
      if (destination !== router.asPath) {
        const beforeRoutingLocale = get(router.asPath.split("/"), "1", false);
        const afterRoutingLocale = get(destination.split("/"), "1", false);
        if (beforeRoutingLocale !== afterRoutingLocale) {
          window.location = destination;
        }
      }
      
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);
};
export default useLocaleChange;