import { useCallback, useMemo } from "react";

import HTMLRender from "@components/core/HTMLRender";
import dayjs from "dayjs";
import format from "string-template";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import locale_ar from "dayjs/locale/ar";
import locale_cs from "dayjs/locale/cs";
import locale_de from "dayjs/locale/de";
import locale_es from "dayjs/locale/es";
import locale_it from "dayjs/locale/it";
import locale_ro from "dayjs/locale/ro";
import locale_vi from "dayjs/locale/vi";
import localizedFormat from "dayjs/plugin/localizedFormat";
import replace from "lodash/replace";
import unescape from "lodash/unescape";
import { useStore } from "@lib/store";

dayjs.extend(localizedFormat);

/**
 * useGlobalVariable
 * Get global variable by name
 * @param {string} name
 * @returns {function} gv
 * @returns {string} gv('name')
 */

/**
 * Use global variable with template replacement:
    gv('ipo_page_general_info', {
        type: 'html', // if type not specified, will return string
        template: {
            ipo_name: data?.name,
        },
    })
 */

export default function useGlobalVariable() {
  const locale = useStore((state) => state.locale);
  const globalVariables = useStore((state) => state.globalVariables);

  let dayJSactiveLocale = "";

  switch (locale) {
    case "ae-ar":
      dayJSactiveLocale = "ar";
      break;
    case "za":
    case "ae":
    case "eu":
      dayJSactiveLocale = "en";
      break;
    case "lat":
      dayJSactiveLocale = "es";
      break;
    case "cz":
      dayJSactiveLocale = "cs";
      break;
    case "vn":
      dayJSactiveLocale = "vi";
      break;
    default:
      dayJSactiveLocale = locale;
      break;
  }

  dayjs.locale(dayJSactiveLocale);

  // set current year and month
  const currentYear = dayjs().format("YYYY");
  const currentMonth = dayjs().format("MMMM");

  const findAttribute = useCallback(
    (name, { template = {}, type = false } = {}) => {
      let result = globalVariables?.[name] || name;
      if (!isEmpty(template)) {
        // replace variables between {{variables}} with text
        Object.keys(template).forEach((key) => {
          if (result && isString(result)) result = replace(result, new RegExp(`{{${key}}}`, "g"), template[key]);
        });
      }
      let formattedResult = format(result || "", {
        year: currentYear,
        month: currentMonth,
      });

      switch (type) {
        case "html": {
          return <HTMLRender data={formattedResult} />;
        }
        default: {
          // some symbols are stores escaped in db, like &amp;, &lt;, &gt; etc, so we need to unescape them
          formattedResult = unescape(isString(formattedResult) ? formattedResult : "");
          formattedResult = isString(formattedResult) ? replace(formattedResult, /<(|\/)p>/g, "") : "";
          return formattedResult;
        }
      }
    },
    [globalVariables, currentYear, currentMonth]
  );

  return {
    gv: findAttribute,
    isValidating: false,
  };
}
