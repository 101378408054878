import PhoneInput from "react-phone-number-input";
import { useField, useFormikContext } from "formik";
import "react-phone-number-input/style.css";

const PhoneFieldInput = ({ label, ...props }) => {
  const [field] = useField(props.name);
  const formikProps = useFormikContext();

  return (
    <PhoneInput
      {...props}
      {...field}
      value={field.value}
      defaultCountry={props.currentUserCountry === "XX" ? "US" : props.currentUserCountry}
      international
      onChange={(value) => {
        formikProps.setFieldValue(props.name, value);
      }}
    />
  );
};

export default PhoneFieldInput;
