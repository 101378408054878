import ModalMantine from "@components/elements/Modal";
import ModalAnalystTemplate from "@components/elements/Modal/ModalAnalyst";
import ChangeLicenseProductType from "@components/elements/Modal/ChangeLicenseProductType";
import ResponseFormModal from "@components/elements/Modal/ResponseFormModal";
import RegisterFormModal from "@components/elements/Modal/RegisterFormModal";
import ChangeLicenseWarning from "@components/elements/Modal/ChangeLicenseWarning";
import ModalAffiliate from "@components/elements/Forms/templates/ModalAffiliate";
import ModalPartners from "@components/elements/Forms/templates/ModalPartners";
import ModalPartnersRetailIB from "@components/elements/Forms/templates/ModalPartnersRetailIB";
import ReferEarnModal from "@components/elements/Modal/ModalReferEarn";
import PartnersRetailIBCalendarResponse from "@components/elements/Forms/templates/FormCalendarPartnersRetailIB/ResponseModal";
import ModalReferAFriend from "@src/components/elements/Forms/templates/ModalReferAFriend";
import ModalSessionExpiration from '../elements/Modal/ModalSessionExpiration';

const ModalProviderConfig = {
  defaultModal: ModalMantine,
  analystModal: ModalAnalystTemplate,
  ChangeLicenseProductType,
  ChangeLicenseWarning,
  formResponse: ResponseFormModal,
  PartnersRetailIBCalendarResponse,
  ModalSessionExpiration,

  // refer_a_friend_registration_form: ReferEarnModal, // is defined in the button component modalName
  affiliate_registration_form: ModalAffiliate, // is defined in the button component modalName
  partners_registration_form: ModalPartners, // is defined in the button component modalName
  partners_retail_ib_registration_form: ModalPartnersRetailIB, // is defined in the button component modalName
  refer_a_friend_registration_form: ModalReferAFriend,
};
export default ModalProviderConfig;
