/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import NextLink from "next/link";
import useLocale from "@lib/store/hooks/useLocale";
import trim from "lodash/trim";
import { usePersistentStore, useStore } from "@lib/store";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import styles from "./Link.module.css";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import { usePageStore } from "@lib/store/store";

const getDefaultLink = ({data,locale}) => {
  let defaultLink = trim(data?.link || data?.href || "");
  if (defaultLink?.includes(`/${locale}/${locale}`)) {
    defaultLink = defaultLink.replace(`/${locale}/${locale}/`, `/${locale}/`);
  }
  return defaultLink;
};

const Link = (props) => {
  const locale = useLocale();
  const urlTrackingParams = usePersistentStore((state) => state.urlTrackingParams, shallow);
  const registrationLink = useRegistrationLink();
  // const registrationLink = usePageStore((state) => state.registrationLink);
  const data = props.data || props;
  const link = getDefaultLink({data,locale});
  const domain = process.env.NEXT_PUBLIC_ACTIVE_DOMAIN || "capex.com";
  const classNames = `${styles.link || ""} ${styles[`link-arrow-${data.arrow}`] || ""} ${
    styles[`${data.color}`] || ""
  } ${props?.className || ""}`;
  const additionalProps = {
    ...(data?.disablePrefetch && { prefetch: false }), // disables prefetch if disablePrefetch is true, used on the pages that have too many links (ex sitemap html)
  };
  if (link?.includes("registration") || link?.includes("login")) {
    return (
      <a
        id={props?.id}
        target={`${props?.blank || (props?.target && props?.target !== "_self") ? "_blank" : "_self"}`}
        className={`${classNames} v1`}
        href={`${link?.includes('/registration') ? registrationLink : link}${urlTrackingParams && urlTrackingParams}`}
      >
        {data.text || props.children}
      </a>
    );
  }
  // Logic for external links that starts with https
  if (link?.startsWith("http") && !link?.includes("online-trading")) {
    const currentHost = `https://${domain}`;
    const isInternalUrl = link?.startsWith(currentHost);
    const isBlank = props?.blank || props?.target || !isInternalUrl || false;
    return (
      <NextLink href={`${link}${urlTrackingParams && urlTrackingParams}`} {...additionalProps}>
        <a
          id={props?.id}
          target={`${isBlank || props?.blank || props?.target ? "_blank" : "_self"}`}
          onClick={props?.onClick || false}
          className={`${classNames} v2`}
          rel={`${isInternalUrl ? "" : "noopener noreferrer"}`}
        >
          {data.text || props.children || ""}
        </a>
      </NextLink>
    );
  }
  //  Logic for /online-trading path which is not hosted by us but has an internal URL
  if (link?.includes("/online-trading")) {
    return (
      <a href={`${link}${urlTrackingParams && urlTrackingParams}`} className={`${classNames} v3`}>
        {data.text || props.children || ""}
      </a>
    );
  }

  // Logic for most of the URLs
  if (link?.startsWith("/")) {
    if (!link.includes(`/${locale}/`)) {
      return (
        <NextLink href={`/${locale}${link}${urlTrackingParams && urlTrackingParams}`} {...additionalProps}>
          <a
            id={props?.id}
            target={`${props?.blank || (props?.target && props?.target !== "_self") ? "_blank" : "_self"}`}
            onClick={props?.onClick || false}
            className={`${classNames} v4`}
          >
            {data.text || props.children || ""}
          </a>
        </NextLink>
      );
    }
  }
  return (
    <NextLink href={`${link}${urlTrackingParams && urlTrackingParams}`} {...additionalProps}>
      <a
        id={props?.id}
        target={`${props?.blank || (props?.target && props?.target !== "_self") ? "_blank" : "_self"}`}
        onClick={props?.onClick || false}
        className={`${classNames} v5`}
      >
        {data.text || props.children || ""}
      </a>
    </NextLink>
  );
};

Link.displayName = "Link";

export default Link;
