import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";

const SWRItem = ({ name, config }) => {
  useSwrHook(name, { ...config });
  return <></>;
};

const SWRGlobalRequests = () => {
  const store = useStore(
    (store) => (
      {
        locale: store.locale,
      },
      shallow
    )
  );
  const list = [
    {
      name: "getGlobalData",
      config: {
        query: {
          locale: store.locale || "en",
        },
      },
    },
    {
      name: "modals",
      config: {
        query: {},
      },
    },
  ];
  return (
    <>
      {list.map((el) => (
        <SWRItem key={el.name} name={el.name} config={el.config} />
      ))}
    </>
  );
};

export default SWRGlobalRequests;
