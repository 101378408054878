import NextLink from "@components/elements/Link";
import useModalHook from "@lib/modals";
import { useStore } from "@lib/store";
import useActiveProductType, { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import { useRouter } from "next/router";
import { shallow } from "zustand/shallow";
import React, { useEffect, useState } from "react";
import styles from "./Button.module.css";

const Button = (props) => {
  const modal = useModalHook();
  const router = useRouter();
  const data = props.data || props;
  const pageKey = useStore((s) => s.page_key, shallow);
  const link  = data.link || data.url || false;

  const onClickEventHandler = (e) => {
    switch (data.actionType) {
      case "DROPDOWN":
        // ctx.dropdownDispatch({ type: 'changeDropdown' });
        break;
      case "LINK":
        if (pageKey?.includes("iframe")) {
          window.open(`${link}`, "_blank");
        } else {
          router.push(link);
        }
        break;
      case "MODAL":
        e.preventDefault();
        try {
          modal.open(data?.modalName, {});
        } catch (err) {
          console.warn("Modal can't be opened.");
        }
        break;
      default:
        break;
    }
  };
  return (
    <button
      id={props?.id}
      type={data.submit ? "submit" : "button"}
      disabled={data.type === "disabled" || data.disabled}
      className={`relative ${styles.btn} ${styles[`btn-size-${data.type === "link" ? "none" : data.size}`] || ""} ${
        styles[`btn-${data.type}`] || ""
      } ${
        data.subLabel
          ? `${styles[`btn-arrow-labeled-${data.arrow}`] || ""}`
          : `${styles[`btn-arrow-${data.arrow}`] || ""}`
      } ${data.fullWidth ? `${styles.fullWidth || ""}` : ""} testtest`}
      onClick={data?.actionType && data?.actionType !== "LINK" ? onClickEventHandler : data.onClick || null}
    >
      <div className={styles.bgHover} />
      {link && data?.actionType !== "MODAL" && (
        <div className={styles.btnLink}>
          <NextLink blank={pageKey?.includes("iframe")} href={link} />
        </div>
      )}
      {data.subLabel ? (
        <div
          className={`flex flex-col ${!data.arrow && !data.fullWidth ? "items-start" : "items-center lg:items-start"}`}
        >
          <span
            className={`${styles[`btn-${data.type}-subLabel`] || ""} ${
              data.subLabelUpperCase ? "uppercase" : ""
            } self-start text-sm opacity-60 ${data.labelPos === "below" ? "order-1" : "order-0"}`}
          >
            {data.subLabel}
          </span>
          <span className={`${data.labelUpperCase ? "uppercase" : ""}`}>{data.label}</span>
        </div>
      ) : (
        <span className={`${data.labelUpperCase ? "uppercase" : ""}`}>{data.label}</span>
      )}
    </button>
  );
};

Button.displayName = "Button";

export default Button;
