/* eslint-disable arrow-body-style */
// index.js
import { find } from "lodash";
import { createContext, useContext } from "react";
import { createStore, useStore as useZustandStore } from "zustand";
import { combine } from "zustand/middleware";

const getDefaultPageState = () => ({
  header_style: { logo: "red", color: "dark" },
  api: process.env.NEXT_PUBLIC_API_STRAPI,
  publicImageHost: process.env.NEXT_PUBLIC_IMAGE_HOST,
  locale: "en",
  locales: [],
  seo: {},
  pageType: null, // page article trading (used for Layout)
  slug: null,
  hide_payment_methods: false,
  name: null,
  paginationProps: {},
  localizations: [],
  pageLocalizations: [],
  header: null,
  navigation: { footer: null, header: null },
  footer: null,
  modals: [], // check if we can have useHooks inside initStore, hasHook
  notifications: [], // hasHook
  sections: [], // !
  logo: null,
  pageSlugs: [],
  globalVariables: [],
  globalNavigation: [],
  globalData: [],
  productTypes: [],
  websiteLanguages: [],
  currentProductTypeKey: "trade",
  currentProductType: {},
  licence: null,
  tableOfContents: [],
  zendeskLoaded: false,
});

const getDefaultPersistentState = () => ({
  headerHeight: 0,
  riskWarningHeight: 0,
  urlTrackingParams: "",
  activeWrongLicenseBar: false,
  wrongLicenceState: false,
  registrationLink:"/registration",
  loginLink:"/login",
})



const getProductTypeFromSessionStorage = (props) => {
  let intermediateProductType = props?.page?.product_type || "trade";
  if (typeof window !== "undefined") {
    let sessionStorageProductTypeKey =
      "trade";
    try {
      sessionStorageProductTypeKey = JSON.parse(window?.sessionStorage?.getItem(`capex:product_type:${props?.app?.locale}`) || 'trade');
    } catch (error) {
      sessionStorageProductTypeKey = "trade";
    }
    intermediateProductType = props?.page?.product_type
      ? props?.page?.product_type
      : sessionStorageProductTypeKey || intermediateProductType;
  }
  return intermediateProductType;
};

const createZustandPageStore = (initialState) => {
  return createStore(
    combine({...getDefaultPageState()}, (set, get) => ({
      // Your actions and additional state
      ...initialState?.app,
      ...initialState?.page,
      productTypes: initialState?.fallback?.layoutSharedData.globalProductTypes.data || [], // these are returned for each page
      globalVariables: initialState?.fallback?.layoutSharedData.globalVariables || [], // these are returned for each page
      pageSlugs: initialState?.fallback?.layoutSharedData?.pageSlugs?.data || [], // these are returned for each page
      websiteLanguages: initialState?.fallback?.layoutSharedData.languages || [], // these are returned for each page
      logoUrl: initialState?.fallback?.layoutSharedData?.globalProductTypes?.data.filter(
              (obj) => obj && obj.attributes && obj.attributes.default_homepage === true
            )[0]?.attributes?.product_homepage?.data?.attributes?.slug || "",
      swrFallbacks: initialState.fallback,
      currentProductTypeKey: getProductTypeFromSessionStorage(initialState),
      currentProductType: (() => {
        const selectedProductType = initialState?.fallback?.layoutSharedData?.globalProductTypes?.data.find(
            (product) => product.attributes.product_type === getProductTypeFromSessionStorage(initialState)
        );
        return selectedProductType;
      })(),
      
      
      setTableOfContents: (tocArray) => set(() => ({ tableOfContents: tocArray })),
      
      setCurrentProductType: (productType) => {
        set((state) => ({
          currentProductTypeKey: productType,
          currentProductType: state.productTypes.find((product) => product.attributes.product_type === productType),
        }));
      },
      getPageUrl: (pageKey) => {
        const { pageSlugs } = get();
        const pageSlug = pageSlugs?.find((page) => page?.attributes?.page_key === pageKey)?.attributes?.slug || "";
        return pageSlug;
      },
      setPageStoreToWindow: () => {
        if (typeof window !== "undefined") {
          window.zustandPageStore = get();
        }
      },
      updateState: (newState) => {
        set(() => ({...newState}));
      },
      getStore: () => {
        return get()
      }
    }))
  );
};
const createZustandPersistentStore = (initialState) => {
  return createStore(
    combine({ ...getDefaultPersistentState(), ...initialState }, (set, get) => ({
      // Your actions and additional state
      updateUrlTrackingParams: (params) => {
        set(() => ({ urlTrackingParams: params }));
      },
      setZendeskLoaded: (state) => set(() => ({ zendeskLoaded: state })),
      setActiveWrongLicenseBar: (state) => set(() => ({ activeWrongLicenseBar: state })),
      setWrongLicenceState: (state) => set(() => ({ wrongLicenceState: state })),
      updateHeaderHeight: (headerHeightState) => set(() => ({ headerHeight: headerHeightState })),
      updateRiskWarningHeight: (riskWarningHeightState) => set(() => ({ riskWarningHeight: riskWarningHeightState })),
      updateModals: (modals) => {
        set({
          modals,
        });
      },

      setPersistentStoreToWindow: () => {
        if (typeof window !== "undefined") {
          window.zustandPersistentStore = get();
        }
      },
    }))
  );
};

const PageStoreContext = createContext(null);
const PersistentStoreContext = createContext(null);

export const PageStoreProvider = PageStoreContext.Provider;
export const PersistentStoreProvider = PersistentStoreContext.Provider;

export const useStore = (selector) => {
  const store = useContext(PageStoreContext);
  if (!store) throw new Error("PageStore is missing the provider");
  return useZustandStore(store, selector);
};

export const usePersistentStore = (selector) => {
  const store = useContext(PersistentStoreContext);
  if (!store) throw new Error("PersistentStore is missing the provider");
  return useZustandStore(store, selector);
};

export const initializePageStore = (preloadedState = {}) => {
  return createZustandPageStore(preloadedState);
};

export const initializePersistentStore = () => {
  return createZustandPersistentStore({});
};
