import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import HTMLRender from "@src/components/core/HTMLRender";
import get from "lodash/get";
import CloseButton from "@src/components/elements/CloseButton";
import styles from "./Modal.module.css";

const ResponseModal = ({ context, id, innerProps }) => {
  const { gv } = useGlobalVariable();
  const text = get(context, "modals.0.props.data.response", "Success");
  const type = get(context, "modals.0.props.type");
  if (type === "SUCCESS") {
    // const message = get(context, "modals.0.props.data");
    // console.log
    return (
      <div className={`center overflow-hidden rounded-xl bg-white ${styles.responseModal}`}>
        <CloseButton closeText={gv("close")} onClick={() => context.closeModal(id)} />
        <HTMLRender data={text} />
      </div>
    );
  }
  return (
    <div className="center mt-6 overflow-hidden rounded-xl bg-white">
      <pre>Error. Please Try again.</pre>
    </div>
  );
};

export default ResponseModal;
