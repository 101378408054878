/* eslint-disable arrow-body-style */
// index.js
import { createContext, useContext } from "react";
import { createStore, useStore as useZustandStore } from "zustand";
import { combine } from "zustand/middleware";

const getDefaultInitialState = () => ({
  // Your default state variables
});

const getProductTypeFromSessionStorage = (props) => {
  let intermediateProductType = props?.page?.product_type || "trade";
  if (typeof window !== "undefined") {
    let sessionStorageProductTypeKey =
      window?.sessionStorage?.getItem(`capex:product_type:${props?.app?.locale}`) || "trade";
    try {
      sessionStorageProductTypeKey = JSON.parse(sessionStorageProductTypeKey);
    } catch (error) {
      sessionStorageProductTypeKey = "trade";
    }
    intermediateProductType = props?.page?.product_type
      ? props?.page?.product_type
      : sessionStorageProductTypeKey || intermediateProductType;
  }
  return intermediateProductType;
};

const createZustandPageStore = (initialState) => {
  return createStore(
    combine({ ...getDefaultInitialState() }, (set, get) => ({
      // Your actions and additional state
      ...initialState?.app,
      ...initialState?.page,
      productTypes: initialState?.fallback?.layoutSharedData.globalProductTypes.data || [], // these are returned for each page
      globalVariables: initialState?.fallback?.layoutSharedData.globalVariables || [], // these are returned for each page
      pageSlugs: initialState?.fallback?.layoutSharedData?.pageSlugs?.data || [], // these are returned for each page
      websiteLanguages: initialState?.fallback?.layoutSharedData.languages || [], // these are returned for each page
      logoUrl: initialState?.fallback?.layoutSharedData?.globalProductTypes?.data.filter(
              (obj) => obj && obj.attributes && obj.attributes.default_homepage === true
            )[0]?.attributes?.product_homepage?.data?.attributes?.slug || "",
      swrFallbacks: initialState.fallback,
      currentProductTypeKey: getProductTypeFromSessionStorage(initialState),
      currentProductType: (() => {
        const selectedProductType = initialState?.fallback?.layoutSharedData?.globalProductTypes?.data.find(
            (product) => product.attributes.product_type === getProductTypeFromSessionStorage(initialState)
        );
        return selectedProductType;
      })(),
    }))
  );
};
const createZustandPersistentStore = (initialState) => {
  return createStore(
    combine({ ...getDefaultInitialState(), ...initialState }, (set, get) => ({
      // Your actions and additional state
    }))
  );
};

const PageStoreContext = createContext(null);
const PersistentStoreContext = createContext(null);

export const PageStoreProvider = PageStoreContext.Provider;
export const PersistentStoreProvider = PersistentStoreContext.Provider;

export const usePageStore = (selector) => {
  const store = useContext(PageStoreContext);
  if (!store) throw new Error("PageStore is missing the provider");
  return useZustandStore(store, selector);
};

export const usePersistentStore = (selector) => {
  const store = useContext(PersistentStoreContext);
  if (!store) throw new Error("PersistentStore is missing the provider");
  return useZustandStore(store, selector);
};

export const initializePageStore = (preloadedState = {}) => {
  return createZustandPageStore(preloadedState);
};

export const initializePersistentStore = () => {
  return createZustandPersistentStore({});
};
