import useModalHook from "@lib/modals";
import CloseButton from "@src/components/elements/CloseButton";
import useFormHandlers from "@src/components/elements/Forms/hooks/useFormHandlers";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import PhoneFieldInput from "@src/components/elements/Forms/components/PhoneFieldInput";
import HTMLRender from "@src/components/core/HTMLRender";
import { useEffect, useState } from "react";
import ErrorLabel from "@src/components/elements/Forms/components/ErrorLabel";
import Countries from "@public/files/array_of_countries.json";
import { getCookie } from "cookies-next";

YupPassword(Yup);

const ModalPartnersRetailIB = ({ context }) => {
  const { modals } = context;
  const [submitResponse, setSubmitResponse] = useState(null);
  const modal = useModalHook();
  const [userDetailsLogin, setUserDetailsLogin] = useState({
    user: "",
    password: "",
  });
  const props = modals && modals.length > 0 ? modals[0].props : undefined;
  const handler = useFormHandlers();
  const { gv } = useGlobalVariable();

  useEffect(() => {
    if (submitResponse && submitResponse?.statusCode == 400) {
      setTimeout(() => {
        setSubmitResponse(null);
      }, 8000);
    }
  }, [submitResponse]);
  if (!props) return null;

  const initialValues = {};

  const PartnersRetailIBValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(<ErrorLabel err="forms_errors_firstname_required" />)
      .matches(/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/, {
        message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
        when: (value) => !/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/.test(value),
      })
      .min(2, <ErrorLabel err="forms_errors_rule_min_length" value="2" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    last_name: Yup.string()
      .required(<ErrorLabel err="forms_errors_lastname_required" />)
      .matches(/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/, {
        message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
        when: (value) => !/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/.test(value),
      })
      .min(2, <ErrorLabel err="forms_errors_rule_min_length" value="2" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    email: Yup.string()
      .email(<ErrorLabel err="forms_errors_email_required" />)
      .required(<ErrorLabel err="forms_errors_email_required" />),
    username: Yup.string()
      .required(<ErrorLabel err="forms_errors_username_required" />)
      .matches(/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/, {
        message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
        when: (value) => !/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/.test(value),
      })
      .min(6, <ErrorLabel err="forms_errors_rule_min_length" value="6" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    country: Yup.string(),
    phone_number: Yup.string()
      .required(<ErrorLabel err="forms_errors_phone_required" />)
      .min(8, <ErrorLabel err="forms_errors_phone_required" />)
      .max(16, <ErrorLabel err="forms_errors_phone_required" />),
    deal_profile: Yup.string().required(<ErrorLabel err="forms_errors_field_required" />),
    password: Yup.string()
      .required(<ErrorLabel err="forms_errors_password_required" />)
      .min(8, <ErrorLabel err="forms_errors_password_rule_min_length" />)
      .max(16, <ErrorLabel err="forms_errors_password_rule_max_length" />)
      .minSymbols(1, <ErrorLabel err="forms_errors_password_rule_symbols" />)
      .minNumbers(1, <ErrorLabel err="forms_errors_password_rule_numbers" />)
      .minLowercase(1, <ErrorLabel err="forms_errors_password_rule_lowercase" />)
      .minUppercase(1, <ErrorLabel err="forms_errors_password_rule_uppercase" />),
    confirm_password: Yup.string()
      .required(<ErrorLabel err="forms_errors_password_confirm" />)
      .oneOf([Yup.ref("password"), null], <ErrorLabel err="forms_errors_password_confirm" />),
    partner_type: Yup.string().required(<ErrorLabel err="forms_errors_field_required" />),
    // ib_experience: Yup.string().required(<ErrorLabel err="forms_errors_field_required" />),
    estimated_clients: Yup.string().required(<ErrorLabel err="forms_errors_field_required" />),
    client_aquire: Yup.string().required(<ErrorLabel err="forms_errors_field_required" />),
    targeted_regions: Yup.string().required(<ErrorLabel err="forms_errors_field_required" />),
    estimated_deposits: Yup.string().required(<ErrorLabel err="forms_errors_field_required" />),
    agreed_to_privacy_policy: Yup.boolean()
      .oneOf([true], <ErrorLabel err="forms_errors_field_required" />)
      .required(<ErrorLabel err="forms_errors_field_required" />),
    agree_receive_promotional_materials: Yup.boolean().oneOf(
      [true],
      <ErrorLabel err="agree_receive_promotional_materials" />
    ),
  });

  const currentUserCountry = getCookie("regulation_country");

  return (
    <div className="rounded-xl bg-white">
      <CloseButton onClick={() => modal.openCloseAll()} />
      {submitResponse && submitResponse.statusCode == "200" && (
        <div className={`center overflow-hidden p-md lg:p-10`}>
          <HTMLRender data={gv("affiliate_form_succes_message")} />
        </div>
      )}
      <form
        name="login"
        id="hiddenLoginForm"
        action="https://go.keywaypartners.com/loginaffiliate/"
        method="post"
        acceptCharset="utf-8"
        className="hidden"
      >
        <input type="user" name="user" value={userDetailsLogin.user} />
        <input type="password" name="password" value={userDetailsLogin.password} />
        <input id="command" name="command" value="logon" type="hidden" />
        <input id="errorurl" name="errorurl" value="https://new-go.keywaypartners.com/login" type="hidden" />
        <input type="submit" value="Login" />
      </form>

      <Formik
        style={{
          display: submitResponse && submitResponse?.statusCode !== 400 ? "none" : "block",
        }}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={PartnersRetailIBValidationSchema}
        onSubmit={async (values) => {
          setUserDetailsLogin({
            user: values.username,
            password: values.password,
          });
          const response = await handler.onSubmitPartnersBI(values).catch((err) => {});
          if (response && response?.statusCode == 200) {
            setSubmitResponse(response);
            //delay 5 seconds
            setTimeout(() => {
              document.getElementById("hiddenLoginForm").submit();
            }, 7500);
          } else {
            setSubmitResponse({
              statusCode: 400,
            });
          }
          console.log("response", response);
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting, validate, submitCount }) => (
          <form onSubmit={handleSubmit} className="rounded-xl bg-white py-lg px-md md:py-xl md:px-lg">
            {console.log("errors", errors)}
            <h3 className="mb-lg text-center text-dark md:mb-xl">{gv("registration_form")}</h3>
            <div>
              <div
                name="schedule"
                id="schedule"
                encType="application/json"
                method="post"
                noValidate="novalidate"
                className="justiy-center flex flex-col flex-wrap md:flex-row  md:justify-between"
              >
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <Field type="text" name="first_name" placeholder={gv("first_name")} />
                  {errors?.first_name && submitCount > 0 && errors?.first_name}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <Field type="text" name="last_name" placeholder={gv("last_name")} />
                  {errors?.last_name && submitCount > 0 && errors?.last_name}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)] ">
                  <Field type="email" name="email" placeholder={gv("email_address")} />
                  {errors?.email && submitCount > 0 && errors?.email}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <Field type="text" name="username" placeholder={gv("username")} />
                  {console.log("submitCount", submitCount)}
                  {errors?.username && submitCount > 0 && errors?.username}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <Field as="select" name="country" placeholder={gv("country")}>
                    {Countries.map((country) => (
                      <option
                        value={country.iso_alpha2_code}
                        key={country.id}
                        selected={country.iso_alpha2_code == currentUserCountry.toUpperCase()}
                      >
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  {errors?.country && submitCount > 0 && errors?.country}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  {/* Phone Number */}
                  <div className="phone-field-wrapper">
                    <PhoneFieldInput
                      currentUserCountry={currentUserCountry.toUpperCase()}
                      label="Phone Number"
                      name="phone_number"
                      type="tel"
                      placeholder={gv("phone")}
                    />
                  </div>
                  {errors?.phone_number && submitCount > 0 && errors?.phone_number}
                </div>
                <div className="margin-0 relative w-full pb-md">
                  {/* Country List Filed as={Component} */}
                  <Field as="select" name="deal_profile">
                    <option selected disabled>
                      {gv("forms_field_name_deal_profile")}
                    </option>
                    <option value="CPA Hybrid">{gv("forms_field_name_deal_profile_cpa_hybrid")}</option>
                    <option value="Spread">{gv("forms_field_name_deal_profile_spread")}</option>
                    <option value="Revenue Share">{gv("forms_field_name_deal_profile_revenue_share")}</option>
                  </Field>
                  {errors?.deal_profile && submitCount > 0 && errors?.deal_profile}
                </div>

                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <Field type="password" name="password" placeholder={gv("password")} />
                  {errors?.password && (submitCount > 0 || touched?.password) && errors?.password}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <Field type="password" name="confirm_password" placeholder={gv("confirm_password")} />
                  {errors?.confirm_password &&
                    (submitCount > 0 || touched?.password || touched?.confirm_password) &&
                    errors?.confirm_password}
                </div>
                <div className="margin-0 relative w-full pb-md">
                  <strong>{gv("business_information")}</strong>
                </div>
                <div className="margin-0 relative w-full pb-md">
                  {/* Country List Filed as={Component} */}
                  <Field as="select" name="partner_type">
                    <option selected disabled>
                      {gv("forms_field_name_partner_type")}
                    </option>
                    <option value="Individual">{gv("forms_field_name_partner_type_individual")}</option>
                    <option value="Corporate">{gv("forms_field_name_partner_type_corporate")}</option>
                  </Field>
                  {errors?.partner_type && submitCount > 0 && errors?.partner_type}
                </div>
                <div className="margin-0 relative flex w-full items-center justify-between whitespace-nowrap pb-md md:w-[calc(50%-1rem)]">
                  <div id="ib_experience">{gv("forms_field_name_ib_experience")}</div>
                  <div className="flex items-center" role="group" aria-labelledby="ib_experience">
                    <label>
                      <Field className="ltr:mr-xs rtl:ml-xs" type="radio" name="ib_experience" value="yes" checked />
                      {gv("yes")}
                    </label>
                    <label className="ltr:pl-lg rtl:pr-lg ltr:lg:pl-xl rtl:lg:pr-xl">
                      <Field className="ltr:mr-xs rtl:ml-xs" type="radio" name="ib_experience" value="no" />
                      {gv("no")}
                    </label>
                  </div>
                  {errors?.ib_experience && submitCount > 0 && errors?.ib_experience}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  {/* Country List Filed as={Component} */}
                  <Field as="select" name="estimated_clients">
                    <option selected disabled>
                      {gv("forms_field_name_estimated_clients")}
                    </option>
                    <option value="1-10">1-10</option>
                    <option value="10-50">10-50</option>
                    <option value="50-100">50-100</option>
                    <option value="100+">100+</option>
                  </Field>
                  {errors?.estimated_clients && submitCount > 0 && errors?.estimated_clients}
                </div>

                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  {/* Country List Filed as={Component} */}
                  <Field as="select" name="client_aquire">
                    <option selected disabled>
                      {gv("forms_field_name_client_aquire")}
                    </option>
                    <option value="websites">{gv("forms_field_name_client_aquire_find_clients_websites")}</option>
                    <option value="Social Networking">
                      {gv("forms_field_name_client_aquire_find_clients_social_networking")}
                    </option>
                    <option value="Seminars">{gv("forms_field_name_client_aquire_find_clients_seminars")}</option>
                    <option value="Offline">{gv("forms_field_name_client_aquire_find_clients_offline")}</option>
                    <option value="other">{gv("forms_field_name_acquire_clients_other")}</option>
                  </Field>
                  {errors?.client_aquire && submitCount > 0 && errors?.client_aquire}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  {/* Country List Filed as={Component} */}
                  <Field as="select" name="targeted_regions">
                    <option selected disabled>
                      {gv("forms_field_name_targeted_regions")}
                    </option>
                    <option value="Europe">{gv("forms_field_name_targeted_regions_europe")}</option>
                    <option value="GCC">GCC</option>
                    <option value="Asia">{gv("forms_field_name_targeted_regions_asia")}</option>
                    <option value="LATAM">{gv("forms_field_name_targeted_regions_latam")}</option>
                    <option value="Vietnam">{gv("forms_field_name_targeted_regions_vietnam")}</option>
                    <option value="South Africa">{gv("forms_field_name_targeted_regions_sa")}</option>
                    <option value="Rest of the World">{gv("forms_field_name_targeted_regions_rest")}</option>
                  </Field>
                  {errors?.targeted_regions && submitCount > 0 && errors?.targeted_regions}
                </div>
                <div className="margin-0 relative w-full pb-md">
                  {/* Country List Filed as={Component} */}
                  <Field as="select" name="estimated_deposits">
                    <option selected disabled>
                      {gv("forms_field_name_estimated_deposits")}
                    </option>
                    <option value="0-1k">0-1k</option>
                    <option value="1-10k">1-10k</option>
                    <option value="10-50k">10-50k</option>
                    <option value="50-100k">50-100k</option>
                    <option value="100k & over">{gv("forms_field_name_estimated_deposits_100k_and_over")}</option>
                  </Field>
                  {errors?.estimated_deposits && submitCount > 0 && errors?.estimated_deposits}
                </div>

                <div className="margin-0 relative w-full pb-md">
                  <Field
                    type="checkbox"
                    name="agreed_to_privacy_policy"
                    id="agreed_to_privacy_policy"
                    placeholder="Agree Privacy Policy"
                  />
                  {/* TODO : replace with privacy_policy */}
                  <label htmlFor="agreed_to_privacy_policy">
                    <HTMLRender data={gv("forms_field_name_partners_bi_accept_terms_1")} />
                  </label>
                  {errors?.agreed_to_privacy_policy && submitCount > 0 && errors?.agreed_to_privacy_policy}
                </div>

                <div className="margin-0 relative w-full pb-md">
                  <Field
                    type="checkbox"
                    name="agree_receive_promotional_materials"
                    id="agree_receive_promotional_materials"
                    placeholder="Agree Receive Promotional Materials"
                  />
                  {/* TODO : replace with global_variable */}
                  <label htmlFor="agree_receive_promotional_materials">
                    <HTMLRender data={gv("forms_field_name_partners_accept_terms_2")} />
                  </label>
                  {errors?.agree_receive_promotional_materials &&
                    submitCount > 0 &&
                    errors?.agree_receive_promotional_materials}
                </div>

                <div className="margin-0 relative w-full pt-md">
                  <div className="button-wrapper">
                    <button
                      style={{
                        backgroundColor: submitResponse && submitResponse.statusCode == 400 ? "red" : "default",
                      }}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {submitResponse && submitResponse?.statusCode == 400
                        ? gv("forms_response_errors_something_went_wrong")
                        : gv("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default ModalPartnersRetailIB;
