/* eslint-disable no-restricted-syntax */
import useModalHook from "@lib/modals";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import useLocale, { useLicenceId } from "@lib/store/hooks/useLocale";
import { getCookie } from "cookies-next";

const languageMapping = (locale) => {
  switch (locale) {
    case "en":
    case "za":
    case "ae":
    case "eu":
      return "en";
    case "es":
    case "lat":
      return "es";
    case "ar":
    case "ae-ar":
      return "ar";
    default:
      return locale;
  }
};

export default function useFormHandlers() {
  const locale = useLocale();
  const companyId = useLicenceId();
  const modal = useModalHook();

  const onSubmitWebinars = async (bodyResponse) => {
    const currentUserCountry = getCookie("regulation_country");
    const requestBody = {
      firstName: bodyResponse.first_name,
      lastName: bodyResponse.last_name,
      email: bodyResponse.email,
      phone: bodyResponse.phone_number,
      intent_group: "Webinar",
      intent_instrument: bodyResponse.name,
      description: bodyResponse.name,
      brand: companyId,
      app: "Webinar",
      country: currentUserCountry,
      webinarKey: bodyResponse.webinarKey,
    };
    const response = await fetch("https://capex.com/api/webinar-registration", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }).then((res) => res.json());
    return response;
  };
  const onSubmitAffiliate = async (bodyResponse) => {
    console.log("🚀 ~ file: useFormHandlers.js ~ line 9 ~ onSubmitAffiliate ~ bodyResponse", bodyResponse);
    try {
      const response = await fetch("https://capex.com/api-wk/v1/create_affiliate", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          Username: bodyResponse.username,
          Firstname: bodyResponse.first_name,
          Lastname: bodyResponse.last_name,
          Email: bodyResponse.email,
          EmailAgain: bodyResponse.confirm_email,
          Password: bodyResponse.password,
          PasswordAgain: bodyResponse.confirm_password,
          Phone: bodyResponse.phone_number,
          Skype: bodyResponse.skype,
          Country: bodyResponse.country,
          Company: bodyResponse.company,
          Website: bodyResponse.website,
          BusinessType: bodyResponse.business_type,
          MiscInfo: bodyResponse.additional_information,
          AgreedToPrivacyPolicy: bodyResponse.agree_privacy_policy ? "1" : "0",
          AgreedToMarketingMaterial: bodyResponse?.agree_receive_promotional_materials ? "1" : "0",
        }),
      }).then((res) => res.json());
      return {
        statusCode: response.statusCode,
        message: response,
      };
    } catch (err) {
      console.log("🚀 ~ file: useFormHandlers.js ~ line 56 ~ onSubmitAffiliate ~ err", err);
      return err;
    }
  };
  const onSubmitReferAFriend = async (bodyResponse) => {
    console.log("🚀 ~ file: useFormHandlers.js ~ line 9 ~ onSubmitReferAFriend ~ bodyResponse", bodyResponse);
    try {
      const response = await fetch("https://capex.com/api-wk/v3/refer-a-friend", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          data: {
            referrer_email: bodyResponse.email,
            referred_name: bodyResponse.name,
            referred_phone: bodyResponse.phone_number,
            referred_email: bodyResponse.referred_email,
            license: companyId,
            locale: languageMapping(locale),
          },
        }),
      }).then((res) => res.json());
      return {
        statusCode: response.status === "ok" ? 200 : 400,
        message: response,
      };
    } catch (err) {
      console.log("🚀 ~ file: useFormHandlers.js ~ line 56 ~ onSubmitAffiliate ~ err", err);
      return {
        statusCode: 400,
      };
    }
  };
  const onSubmitPartners = async (bodyResponse) => {
    console.log("🚀 ~ file: useFormHandlers.js ~ line 55 ~ onSubmitPartners ~ bodyResponse", bodyResponse);
    try {
      const response = await fetch("https://api-v3.keywaygroup.com/private/register/partners/partner", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          Firstname: bodyResponse.first_name,
          Lastname: bodyResponse.last_name,
          Email: bodyResponse.email,
          Phone: bodyResponse.phone_number,
          Skype: bodyResponse.skype,
          Country: bodyResponse.country,
          Citizenship: bodyResponse.citizenship,
          Company: bodyResponse.company,
          Website: bodyResponse.website,
          BusinessType: bodyResponse.business_type,
          ClientAquire: bodyResponse.acquire_clients,
          ClientNumber: bodyResponse.how_many_clients,
          MiscInfo: bodyResponse.additional_information,
          AgreedToPrivacyPolicy: bodyResponse.agree_privacy_policy ? "1" : "0",
          AgreedToMarketingMaterial: bodyResponse?.agree_receive_promotional_materials ? "1" : "0",
        }),
      }).then((res) => res.json());
      return {
        statusCode: response.statusCode,
        message: response,
      };
    } catch (err) {
      console.log("🚀 ~ file: useFormHandlers.js ~ line 56 ~ onSubmitAffiliate ~ err", err);
    }
  };
  const onSubmitPartnersBI = async (bodyResponse) => {
    let localizedLanguage;
    let licence = "";
    let sourceType;
    let importFromAffiliate = "";
    let am = "";
    switch (locale) {
      case "en": {
        localizedLanguage = "English";
        licence = "FSA";
        sourceType = "Retail IB Capex.com SEY";
        importFromAffiliate = "44752";
        am = "159";
        break;
      }
      case "ae": {
        localizedLanguage = "English";
        licence = "ADGM";
        sourceType = "Retail IB Capex.com AE";
        importFromAffiliate = "44753";
        am = "159";
        break;
      }
      case "za": {
        localizedLanguage = "English";
        licence = "FSCA";
        sourceType = "Retail IB  CAPEX.com ZA";
        importFromAffiliate = "44754";
        am = "159";
        break;
      }
      case "eu": {
        localizedLanguage = "English";
        break;
      }
      case "ar": {
        localizedLanguage = "Arabic";
        licence = "FSA";
        sourceType = "Retail IB Capex.com SEY";
        importFromAffiliate = "44752";
        am = "159";
        break;
      }
      case "ae-ar": {
        localizedLanguage = "Arabic";
        licence = "ADGM";
        sourceType = "Retail IB Capex.com AE";
        importFromAffiliate = "44753";
        am = "159";
        break;
      }
      case "es": {
        localizedLanguage = "Spanish";
        break;
      }
      case "lat": {
        localizedLanguage = "Spanish";
        licence = "FSA";
        sourceType = "Retail IB Capex.com SEY";
        importFromAffiliate = "44752";
        am = "159";
        break;
      }
      case "vn": {
        localizedLanguage = "Vietnamese";
        licence = "FSA";
        sourceType = "Retail IB Capex.com SEY";
        importFromAffiliate = "44752";
        am = "159";
        break;
      }
      default:
        localizedLanguage = "English";
    }
    console.log("🚀 ~ file: useFormHandlers.js ~ line 55 ~ onSubmitPartnersBI ~ bodyResponse", bodyResponse);
    try {
      const response = await fetch("https://capex.com/api/register/partners", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          firstname: bodyResponse.first_name,
          lastName: bodyResponse.last_name,
          email: bodyResponse.email,
          username: bodyResponse.username,
          country: bodyResponse.country,
          phone: bodyResponse.phone_number,
          password: bodyResponse.password,
          skype: bodyResponse.client_aquire,
          CustomField1: bodyResponse.ib_experience || "Has IB Experience",
          CustomField4: bodyResponse.partner_type,
          CustomField3: bodyResponse.deal_profile,
          CustomField2: licence, // FIX: dynamicly add it
          ZIPCode: bodyResponse.estimated_clients,
          SourceType: sourceType, // FIX: dynamicly add it
          CustomField5: bodyResponse.targeted_regions,
          MiscInfo: bodyResponse.estimated_clients,
          AgreedToPrivacyPolicy: bodyResponse.agreed_to_privacy_policy ? "1" : "0",
          AgreedToMarketingMaterial: bodyResponse.agreed_to_marketing_material ? "1" : "0",
          ImportFromAffiliate: importFromAffiliate, // FIX: dynamicly add it
          AM: am, // FIX: dynamicly add it
          SiteLanguage: localizedLanguage, // FIX : dynamicly add it
        }),
      }).then((res) => res.json());
      const bodyRequestForLead = new URLSearchParams();

      for (const [key, value] of Object.entries({
        firstname: bodyResponse.first_name,
        lastname: bodyResponse.last_name,
        email: bodyResponse.email,
        phone: bodyResponse.phone_number,
        country: bodyResponse.country,
        affiliate: "44753",
        brand: "68",
        allowMarketingEmails: "true",
      })) {
        bodyRequestForLead.append(key, value);
      }
      const leadResponse = await fetch("https://api.keywaygroup.com/private/register/v2/lead", {
        method: "POST",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        body: bodyRequestForLead.toString(),
      }).then((res) => res.json());
      const emailResponse = await fetch("https://capex.com/api/register/partners/delivery-templates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            license: licence,
            firstname: bodyResponse.first_name,
            email: bodyResponse.email,
            locale,
          },
        }),
      }).then((res) => res.json());

      // $.ajax(deliveryTemplateSettings);
      // $.ajax(settings).then(function (response) {
      //   console.log(response);
      //   setTimeout(function () {
      //     $("form[name=login] input[name=user]").val(requestJSON.username);
      //     $("form[name=login] input[name=password]").val(requestJSON.password);
      //     $("form[name=login]").submit();
      //   }, 5000);
      // });

      return {
        statusCode: response.statusCode || 200,
        message: response,
      };
    } catch (err) {
      console.log("🚀 ~ file: useFormHandlers.js ~ line 56 ~ onSubmitAffiliate ~ err", err);
      return {
        statusCode: 400,
      };
    }
  };
  const onSubmitRegister = async (bodyResponse) => {
    // return ok
    // catch : return new Error("");
  };

  const onSubmitPartnersBICalendar = async (bodyResponse) => {
    //TODO GET LICENSE CODE.

    try {
      const response = await fetch("https://capex.com/api/register/partners/calendar", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          ...bodyResponse,
          license: companyId,
          language: languageMapping(locale),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
      }).then((res) => res.json());
      return response;
      // throw error
    } catch (err) {
      return err;
      // modal.open("formResponse", { type: "FAILED" });
    }
  };

  return {
    onSubmitWebinars,
    onSubmitAffiliate,
    onSubmitPartners,
    onSubmitPartnersBI,
    onSubmitPartnersBICalendar,
    onSubmitReferAFriend,

    onSubmitRegister,
  };
}
