/* eslint-disable import/prefer-default-export */
// StoreProvider.js
import { useEffect, useRef } from "react";
import { initializePageStore, initializePersistentStore, PageStoreProvider, PersistentStoreProvider } from "./index";

let persistentStore;

export const StoreProvider = ({ children, ...props }) => {
  const pageStoreRef = initializePageStore(props);

  // if (!pageStoreRef.current) {
  //   pageStoreRef.current = initializePageStore(props); // Initialize the page-specific store
  // }

  if (!persistentStore) {
    persistentStore = initializePersistentStore(); // Initialize the persistent store once
  }

  useEffect(() => {
    // if (pageStoreRef.current) {
    //   const { updateState } = pageStoreRef.current.getState();
    //   updateState(props);

    //   // Debugging: log the current state
    //   console.log('Updated state:', pageStoreRef.current.getState());
    // }
  }, [props]);

  return (
    <PageStoreProvider value={pageStoreRef}>
    {/* <PageStoreProvider value={pageStoreRef.current}> */}
        <PersistentStoreProvider value={persistentStore}>
          {children}
        </PersistentStoreProvider>
    </PageStoreProvider>
  );
};
