import Image from "next/image";

export default function ImageWidthHeight({ src, priority, ...rest }) {
  return (
    <div className="next-image-wrapper">
      <Image
        unoptimized
        src={src}
        alt={rest?.alt || rest?.alternativeText || ""}
        title={ !rest?.title && rest?.alt || !rest?.title && rest?.alternativeText || rest?.title || ""}
        quality={100}
        objectFit={rest?.objectFit || "cover"}
        priority={priority}
        {...rest}
      />
    </div>
  );
}
