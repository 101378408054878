import HTMLRender from "@components/core/HTMLRender";
import style from "./Modal.module.css";
import NextImage from "../Image";
import Button from "@components/elements/Button";
import CloseButton from "../CloseButton";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import useSetProductType from "@lib/store/hooks/useActiveProductType";

const ChangeLicenseProductType = ({ context, id, innerProps }) => {
  const { gv, isValidating } = useGlobalVariable();
  const { changeProductType } = useSetProductType();
  if (isValidating) return null;
  return (
    <div className="mt-6 overflow-hidden rounded-xl  bg-white p-lg text-center ltr:lg:text-left rtl:lg:text-right">
      <CloseButton closeText={gv("close")} onClick={() => context.closeModal(id)} />
      {/* 
        TODO: add a logic to detect if the destination product is Crypto and the user coutnry is banned for it and display a different text
        if (destinationProductIsCrypto && isCryptoBanned) {
          <HTMLRender data={gv("change_license_crypto_banned")} />
        } else {
          display the default text
        }
      */}
      <div className="pb-md">
        <HTMLRender data={gv("important_legal_information")} />
      </div>
      <HTMLRender
        data={gv("change_license_warning", {
          template: {
            destination_language: `/${innerProps?.destinationLocaleLicense?.locale}`,
            destination_operated_by: gv(`${innerProps?.destinationLocaleLicense?.company_id}_operated_by`),
            destination_regulated_by: gv(`${innerProps?.destinationLocaleLicense?.company_id}_regulated_by`),
            destination_license_nr: gv(`${innerProps?.destinationLocaleLicense?.company_id}_license_nr`),
          },
        })}
      />
      <div className="lg:flex flex-col  items-center justify-center pt-md lg:flex-row lg:pt-lg">
        <div className="pb-md lg:px-sm lg:pb-0">
          <Button
            type="primary"
            size="sm"
            fullWidth
            label={gv("change_license_text_accept")}
            subLabel={gv("change_license_text_accept_sub")}
            onClick={() => changeProductType(innerProps?.product, innerProps?.redirect_to_different_license)}
          />
        </div>
        <div className="lg:px-sm">
          <Button
            size="sm"
            fullWidth
            type="primary"
            onClick={() => context.closeModal(id)}
            label={gv("change_license_text_decline")}
            subLabel={gv("change_license_text_decline_sub")}
          />
        </div>
      </div>
      {/* <div className={"relative m-0 flex items-center justify-between gap-0 bg-secondary p-0 align-middle"}>
        <div className="text-white">
          <h2 className="px-lg font-bold">{name}</h2>
        </div>
      </div>
      <div
        className={style.containerAuthor}
        style={{
          padding: "2rem",
          lineHeight: "1.75",
        }}
      >
        <HTMLRender data={innerProps?.attributes?.content.length > 0 && innerProps.attributes?.content} />
      </div> */}
    </div>
  );
};

export default ChangeLicenseProductType;
