import "../styles/globals.css";
import ApiProviders from "@components/context/API";
import useUrlTracking from "@lib/store/hooks/useUrlTracking";
import useLocaleChange from "@lib/store/hooks/useLocaleChange";
import useDirectionChange from "@lib/store/hooks/useDirectionChange";
import isEqual from "lodash/isEqual";
import React from "react";
import { StoreProvider } from "@lib/store/StoreProvider";

const ChildComponent = React.memo(
  ({ Component, pageProps }) => {
    useUrlTracking();
    useLocaleChange(pageProps);
    useDirectionChange(pageProps);
    return <Component {...pageProps} />;
  },
  (prevProps, nextProps) => {
    const hasSlug = prevProps?.pageProps?.page?.slug && nextProps?.pageProps?.page?.slug ? true : false;
    const hasLocale = prevProps?.pageProps?.locale && nextProps?.pageProps?.locale ? true : false;
    return (
      isEqual(prevProps, nextProps) &&
      (hasSlug ? isEqual(prevProps?.pageProps?.page?.slug, nextProps?.pageProps?.page?.slug) : true) &&
      (hasLocale ? isEqual(prevProps?.pageProps?.locale, nextProps?.pageProps?.locale) : true)
    );
  }
);

ChildComponent.displayName = "ChildComponent";

function MyApp({ Component, pageProps }) {
  return (
    <StoreProvider {...pageProps}>
      <ApiProviders globalData={pageProps?.app} fallbackSwr={pageProps?.fallback}>
        <ChildComponent Component={Component} pageProps={pageProps} />
      </ApiProviders>
    </StoreProvider>
  );
}

export default MyApp;
