import { useEffect } from "react";
import { usePersistentStore, useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import { getCookie } from "cookies-next";

const useUrlTracking = () => {
  const { updateUrlTrackingParams, urlTrackingParams } = usePersistentStore((state) => ({
    updateUrlTrackingParams: state.updateUrlTrackingParams,
    urlTrackingParams: state.urlTrackingParams,
  }), shallow);
  // console.log("urlTrackingParams s/tore", urlTrackingParams);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleDecline = () => {
        const searchparams = window?.location?.search || "";
        updateUrlTrackingParams(searchparams);
      };

      const getTrackingCookie = () => {
        let hasDefinedTheTrackingParams = false;
        // when the browser cookies are forbidden by browser configuration, the tracking cookie may be missing
        const hasTrackingCookie = getCookie('tracking');
        const searchparams = window?.location?.search || "";
        if (!hasTrackingCookie) {
          updateUrlTrackingParams(searchparams);
          hasDefinedTheTrackingParams = true;
        }

        // the logic for the refresh page
        const Consent = getCookie("CookieConsent");
        try {
          const ConsentJson = JSON.parse(Consent.replace(/%2c/g, ",").replace(/'/g, '"').replace(/([{\\[,])\s*([a-zA-Z0-9_]+?):/g, '$1"$2":') || "{}");
          if (ConsentJson?.marketing === false) {
            if (!hasDefinedTheTrackingParams && urlTrackingParams === '' && searchparams !== '') {
              updateUrlTrackingParams(searchparams);
              hasDefinedTheTrackingParams = true;
            }
          }
        } catch (error) {
          console.log('[Cookie Consent] Failed.', error)
        }
      }

      getTrackingCookie();

      window.addEventListener("CookiebotOnDecline", handleDecline);

      // verify if tracking cookie doesn't exist, if it doesn't exist then get the search params and updateUrlTrackingParams


      return () => {
        window.removeEventListener("CookiebotOnDecline", handleDecline);
      };
    }
  }, []);
};

export default useUrlTracking;
