import useModalHook from "@lib/modals";
import CloseButton from "@src/components/elements/CloseButton";
import useFormHandlers from "@src/components/elements/Forms/hooks/useFormHandlers";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import PhoneFieldInput from "@src/components/elements/Forms/components/PhoneFieldInput";
import HTMLRender from "@src/components/core/HTMLRender";
import { useState } from "react";
import ErrorLabel from "@src/components/elements/Forms/components/ErrorLabel";
import Countries from "@public/files/array_of_countries.json";
import { getCookie } from "cookies-next";

YupPassword(Yup);

const ModalPartners = ({ context }) => {
  const { modals } = context;
  const [submitResponse, setSubmitResponse] = useState(null);
  const modal = useModalHook();

  const props = modals && modals.length > 0 ? modals[0].props : undefined;
  const handler = useFormHandlers();
  const { gv } = useGlobalVariable();
  if (!props) return null;

  const initialValues = {};

  const PartnersValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(<ErrorLabel err="forms_errors_firstname_required" />)
      .matches(/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/, {
        message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
        when: (value) => !/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/.test(value),
      })
      .min(2, <ErrorLabel err="forms_errors_rule_min_length" value="2" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    last_name: Yup.string()
      .required(<ErrorLabel err="forms_errors_lastname_required" />)
      .matches(/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/, {
        message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
        when: (value) => !/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/.test(value),
      })
      .min(2, <ErrorLabel err="forms_errors_rule_min_length" value="2" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    email: Yup.string()
      .email(<ErrorLabel err="forms_errors_email_required" />)
      .required(<ErrorLabel err="forms_errors_email_required" />),
    phone_number: Yup.string()
      .required(<ErrorLabel err="forms_errors_phone_required" />)
      .min(8, <ErrorLabel err="forms_errors_phone_required" />)
      .max(16, <ErrorLabel err="forms_errors_phone_required" />),
    skype: Yup.string(),
    country: Yup.string(),
    citizenship: Yup.string(),
    company: Yup.string(),
    business_type: Yup.string(),
    website: Yup.string(),
    agree_privacy_policy: Yup.boolean()
      .oneOf([true], <ErrorLabel err="forms_errors_privacy_policy_required" />)
      .required(<ErrorLabel err="forms_errors_privacy_policy_required" />),
    agree_receive_promotional_materials: Yup.boolean().oneOf(
      [true],
      <ErrorLabel err="agree_receive_promotional_materials" />
    ),
  });
  const currentUserCountry = getCookie("regulation_country");

  return (
    <div className="rounded-xl bg-white">
      <CloseButton onClick={() => modal.openCloseAll()} />
      {submitResponse && submitResponse.statusCode == "200" && (
        <div className={`center overflow-hidden p-md lg:p-10 `}>
          <HTMLRender data={gv("affiliate_form_succes_message")} />
        </div>
      )}
      {submitResponse && submitResponse.statusCode != "200" && (
        <div className={`center overflow-hidden p-md lg:p-10 `}>
          <HTMLRender data={gv("forms_response_errors_something_went_wrong")} />
        </div>
      )}
      {!submitResponse && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={PartnersValidationSchema}
          onSubmit={async (values) => {
            const response = await handler.onSubmitPartners(values).catch((err) => {});
            setSubmitResponse(response);
            console.log("response", response);
          }}
        >
          {({ errors, touched, handleSubmit, isSubmitting, validate, submitCount }) => (
            <form onSubmit={handleSubmit} className="rounded-xl bg-white py-lg px-md md:py-xl md:px-lg">
              {console.log("errors", errors)}
              <h3 className="mb-lg text-center text-dark md:mb-xl">{gv("registration_form")}</h3>
              <div>
                <div
                  name="schedule"
                  id="schedule"
                  encType="application/json"
                  method="post"
                  noValidate="novalidate"
                  className="justiy-center flex flex-col flex-wrap md:flex-row  md:justify-between"
                >
                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    <Field type="text" name="first_name" placeholder={gv("first_name")} />
                    {errors?.first_name && submitCount > 0 && errors?.first_name}
                  </div>
                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    <Field type="text" name="last_name" placeholder={gv("last_name")} />
                    {errors?.last_name && submitCount > 0 && errors?.last_name}
                  </div>
                  <div className="margin-0 relative w-full pb-md ">
                    <Field type="email" name="email" placeholder={gv("email_address")} />
                    {errors?.email && submitCount > 0 && errors?.email}
                  </div>
                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    {/* Phone Number */}
                    <div className="phone-field-wrapper">
                      <PhoneFieldInput
                        currentUserCountry={currentUserCountry.toUpperCase()}
                        label="Phone Number"
                        name="phone_number"
                        type="tel"
                        placeholder={gv("phone")}
                      />
                    </div>
                    {errors?.phone_number && submitCount > 0 && errors?.phone_number}
                  </div>
                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    <Field type="text" name="skype" placeholder={gv("skype")} />
                    {errors?.skype && submitCount > 0 && errors?.skype}
                  </div>
                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    <Field as="select" name="country" placeholder={gv("country")}>
                      {Countries.map((country) => (
                        <option
                          value={country.iso_alpha2_code}
                          key={country.id}
                          selected={country.iso_alpha2_code == currentUserCountry.toUpperCase()}
                        >
                          {country.name}
                        </option>
                      ))}
                    </Field>
                    {errors?.country && submitCount > 0 && errors?.country}
                  </div>
                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    <Field type="text" name="citizenship" placeholder={gv("forms_field_name_citizenship")} />
                    {errors?.citizenship && submitCount > 0 && errors?.citizenship}
                  </div>
                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    <Field type="text" name="company" placeholder={gv("forms_field_name_company")} />
                    {errors?.company && submitCount > 0 && errors?.company}
                  </div>
                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    <Field type="text" name="website" placeholder={gv("website")} />
                    {errors?.website && submitCount > 0 && errors?.website}
                  </div>

                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    {/* Country List Filed as={Component} */}
                    <Field as="select" name="business_type">
                      <option selected disabled>
                        {gv("forms_field_name_business_type")}
                      </option>
                      <option value="Individual">{gv("forms_field_name_business_type_individual_introducer")}</option>
                      <option value="Franchise">{gv("forms_field_name_business_type_franchise_partner")}</option>
                      <option value="White Label">{gv("forms_field_name_business_type_white_label")}</option>
                    </Field>
                    {errors?.business_type && submitCount > 0 && errors?.business_type}
                  </div>
                  <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                    {/* Country List Filed as={Component} */}
                    <Field as="select" name="acquire_clients">
                      <option selected disabled>
                        {gv("forms_field_name_acquire_clients")}
                      </option>
                      <option value="education">{gv("forms_field_name_acquire_clients_education")}</option>
                      <option value="signals">{gv("forms_field_name_acquire_clients_signals")}</option>
                      <option value="social-media">{gv("forms_field_name_acquire_clients_social_media")}</option>
                      <option value="other">{gv("forms_field_name_acquire_clients_other")}</option>
                    </Field>
                    {errors?.acquire_clients && submitCount > 0 && errors?.acquire_clients}
                  </div>
                  <div className="margin-0 relative w-full pb-md">
                    <Field type="text" name="how_many_clients" placeholder={gv("forms_field_name_how_many_clients")} />
                    {errors?.how_many_clients && submitCount > 0 && errors?.how_many_clients}
                  </div>

                  <div className="margin-0 relative w-full pb-md">
                    <Field
                      type="textarea"
                      as="textarea"
                      name="additional_information"
                      placeholder={gv("forms_field_name_additional_info")}
                    />
                    {errors?.additional_information && submitCount > 0 && errors?.additional_information}
                  </div>
                  <div className="margin-0 relative w-full pb-md">
                    <Field
                      type="checkbox"
                      name="agree_privacy_policy"
                      id="agree_privacy_policy"
                      placeholder="Agree Privacy Policy"
                    />
                    {/* TODO : replace with privacy_policy */}
                    <label htmlFor="agree_privacy_policy">
                      <HTMLRender data={gv("forms_field_name_partners_accept_terms_1")} />
                    </label>
                    {errors?.agree_privacy_policy && submitCount > 0 && errors?.agree_privacy_policy}
                  </div>
                  <div className="margin-0 relative w-full pb-md">
                    <Field
                      type="checkbox"
                      name="agree_receive_promotional_materials"
                      id="agree_receive_promotional_materials"
                      placeholder="Agree Receive Promotional Materials"
                    />
                    {/* TODO : replace with global_variable */}
                    <label htmlFor="agree_receive_promotional_materials">
                      <HTMLRender data={gv("forms_field_name_partners_accept_terms_2")} />
                    </label>
                    {errors?.agree_receive_promotional_materials &&
                      submitCount > 0 &&
                      errors?.agree_receive_promotional_materials}
                  </div>

                  <div className="margin-0 relative w-full pt-md">
                    <div className="button-wrapper">
                      <button type="submit" disabled={isSubmitting}>
                        {gv("submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
export default ModalPartners;
