import HTMLRender from "@components/core/HTMLRender";
import style from "./Modal.module.css";
import NextImage from "../Image";
import Button from "@components/elements/Button";
import CloseButton from "../CloseButton";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import useActiveProductType from "@lib/store/hooks/useActiveProductType";
import { useRouter } from "next/router";

const ChangeLicenseWarning = ({ context, id, innerProps }) => {
  const router = useRouter();
  const { gv, isValidating } = useGlobalVariable();
  if (isValidating) return null;
  return (
    <div className="mt-6 overflow-hidden rounded-xl  bg-white  p-lg text-center ltr:lg:text-left rtl:lg:text-right">
      <CloseButton closeText={gv("close")} onClick={() => context.closeModal(id)} />
      <div>
        <div id="change_licence_modal_title" className="pb-md">
          <HTMLRender data={gv("important_legal_information")} />
        </div>
        <div id="change_licence_modal_content">
          <HTMLRender
            data={gv("change_license_warning", {
              template: {
                destination_language: `/${innerProps?.destinationLocaleLicense?.locale}`,
                destination_operated_by: gv(`${innerProps?.destinationLocaleLicense?.company_id}_operated_by`),
                destination_regulated_by: gv(`${innerProps?.destinationLocaleLicense?.company_id}_regulated_by`),
                destination_license_nr: gv(`${innerProps?.destinationLocaleLicense?.company_id}_license_nr`),
              },
            })}
          />
        </div>
      </div>
      <div className="lg:flex items-center  flex-col lg:flex-row justify-center pt-md lg:pt-lg">
        <div className="pb-md lg:pb-0 lg:px-sm">
          <Button
            id="change_licence_modal_accept_btn"
            type="primary"
            size="sm"
            label={gv("change_license_text_accept")}
            subLabel={gv("change_license_text_accept_sub")}
            labelPos="below"
            fullWidth
            onClick={() => {
              router.push(innerProps.destinationUrl);
              context.closeModal(id);
            }}
          />
        </div>
        <div className="lg:px-sm">
          <Button
            id="change_licence_modal_disregard_btn"
            size="sm"
            type="primary"
            fullWidth
            onClick={() => context.closeModal(id)}
            label={gv("change_license_text_decline")}
            labelPos="below"
            subLabel={gv("change_license_text_decline_sub")}
          />
        </div>
      </div>
      {/* <div className={"relative m-0 flex items-center justify-between gap-0 bg-secondary p-0 align-middle"}>
        <div className="text-white">
          <h2 className="px-lg font-bold">{name}</h2>
        </div>
      </div>
      <div
        className={style.containerAuthor}
        style={{
          padding: "2rem",
          lineHeight: "1.75",
        }}
      >
        <HTMLRender data={innerProps?.attributes?.content.length > 0 && innerProps.attributes?.content} />
      </div> */}
    </div>
  );
};

export default ChangeLicenseWarning;
