import HTMLRender from '@components/core/HTMLRender';
import Button from '../Button';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import Loaders from '../Loaders';
import { useState } from 'react';


const ModalSessionExpiration = () => {
    const { gv } = useGlobalVariable();
    const [buttonPressed, setButtonPressed] = useState(false);
    return (
        <div className="mt-6 overflow-hidden rounded-xl  bg-white  p-lg text-center">
            <div className='w-full'>
                <HTMLRender data={gv("refresh_modal_text")} />
            </div>

            <div className="flex justify-center pt-lg">
                {buttonPressed ?
                    <Loaders color="primary" />
                    :
                    <Button
                        type="primary"
                        size="lg"
                        label={gv("refresh_modal_button")}
                        onClick={() => { setButtonPressed(true); window.location.reload() }}
                    />
                }

            </div>
        </div>
    );
}

export default ModalSessionExpiration;