import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";

/**
 * Current locale of the website
 *
 * @returns {String} locale, ex: 'en'
 */

export default function useLocale() {
  const locale = useStore((s) => s.locale, shallow);
  return locale || null;
}

export function useLocaleObject() {
  const localeObject = useStore((s) => s?.languages, shallow);
  return localeObject || null;
}
export function useLicenceId() {
  const companyValue = useStore((s) => s?.languages?.company_id, shallow);
  const company_id = companyValue.split("_")?.[1];
  return company_id || null;
}
export function useLicenceName() {
  const companyValue = useStore((s) => s?.languages?.company_id, shallow);
  const company_id = companyValue.split("_")?.[0];
  return company_id || null;
}
