import ImageFill from "./components/ImageFill";
import ImageWidthHeight from "./components/ImageWidthHeight";
import useSrc from "./hooks/useSrc";

const NextImage = (props) => {
  let image = useSrc(props);
  if (!image?.src) return null;
  //   if (!image?.updatedAt) {
  //     console.log("image without updatedAt", image);
  //   }
  const alt = props?.alt || props?.alternativeText || props?.data?.attributes?.alternativeText || "";
  const title = props?.title || !props?.title && props?.alt || props?.alternativeText || props?.data?.attributes?.alternativeText || "";
  const updatedAt = image?.updatedAt ? `?updated_at=${image?.updatedAt}` : "";

  if (!!props.width && !!props.height)
    return <ImageWidthHeight {...props} title={title} alt={alt} src={`${image.src}${updatedAt}`} priority={image.priority} />;
  return <ImageFill {...props} title={title} alt={alt} priority={image.priority} src={`${image.src}${updatedAt}`} />;
};

export default NextImage;
