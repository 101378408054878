/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState } from "react";
import format from "string-template";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale_de from "dayjs/locale/de";
import locale_it from "dayjs/locale/it";
import locale_es from "dayjs/locale/es";
import locale_ro from "dayjs/locale/ro";
import locale_cs from "dayjs/locale/cs";
import locale_vi from "dayjs/locale/vi";
import locale_ar from "dayjs/locale/ar";
import Button from "@components/elements/Button";
import NextLink from "@components/elements/Link";
import { useStore } from "@lib/store";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import parse, { domToReact } from "html-react-parser";
import get from "lodash/get";
import useSWR from "swr";
import { shallow } from "zustand/shallow";
import isObject from "lodash/isObject";
import has from "lodash/has";
import NaturalImage from "../NaturalImage";
import styles from "./HTMLRender.module.css";
import isEqual from "lodash/isEqual";

dayjs.extend(localizedFormat);

// const fetcher = (...args) => fetch(...args).then((res) => res.json());

// function MentionSWR({ attribs }) {
//   const store = useStore(
//     (store) => ({
//       fallbackVariables: store.fallbackVariables,
//     }),
//     shallow
//   );
//   const getCurrentVariable = store?.fallbackVariables?.find((el) => Object.keys(el)[0] === attribs["data-mention"]);
//   const { data, isValidating, error } = useSWR(
//     process.env.NEXT_PUBLIC_API_STRAPI + Object.values(getCurrentVariable)[0].api,
//     fetcher
//   );

//   let variableValueText = undefined;
//   // the first paragraph of the variable must be replaced with span
//   if (isValidating == false && error == undefined) {
//     variableValueText = data?.data?.attributes?.text.replace(/\"/g, "'");
//     variableValueText = variableValueText.replace("p", "span");
//     variableValueText = variableValueText.split("").reverse().join("");
//     variableValueText = variableValueText.replace("p", "naps"); //naps = span
//     variableValueText = variableValueText.split("").reverse().join("");
//   }
//   return <HTMLRender data={variableValueText || Object.values(getCurrentVariable)[0].text} />;
// }
function parseWithHTML(text, ToC) {
  const { gv } = useGlobalVariable();
  const locale = useStore(store=>store.locale);
  const setTableOfContents = useStore(store=>store.setTableOfContents);
  const tableOfContents = useStore(store=>store.tableOfContents);
  let dayJSactiveLocale = "";

  switch (locale) {
    case "ae-ar":
      dayJSactiveLocale = "ar";
      break;
    case "za":
    case "ae":
    case "eu":
      dayJSactiveLocale = "en";
      break;
    case "lat":
      dayJSactiveLocale = "es";
      break;
    case "cz":
      dayJSactiveLocale = "cs";
      break;
    case "vn":
      dayJSactiveLocale = "vi";
      break;
    default:
      dayJSactiveLocale = locale;
      break;
  }

  dayjs.locale(dayJSactiveLocale);

  // set current year and month
  const currentYear = dayjs().format("YYYY");
  const currentMonth = dayjs().format("MMMM");

  const formattedText = format(text || "", {
    year: currentYear,
    month: currentMonth,
  });
  function deepFindTextFromTag(obj, keys) {
    const result = [];

    // recursive function
    function search(internalSearchObj, searchKeys = []) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in internalSearchObj) {
        if (has(internalSearchObj, key)) {
          if (searchKeys.includes(key)) {
            result.push(internalSearchObj[key]);
          }
          const forbiddensearchKeys = ["parent", "next", "prev", "startIndex", "endIndex", "attribs"];
          if (isObject(internalSearchObj[key]) && !forbiddensearchKeys.includes(key)) {
            search(internalSearchObj[key], searchKeys);
          }
        }
      }
    }

    search(obj, keys);
    const parsedTextFromData = (result || [""]).join(" ");
    return parsedTextFromData;
  }
  const tocArray = [];

  const parseStyleString = (styleString) => {
    const styleObj = {};
    const headerStyles = styleString?.split(";");
    headerStyles?.forEach((style) => {
      const [property, value] = style.split(":");
      if (property && value) {
        styleObj[property.trim()] = value.trim();
      }
    });
    return styleObj;
  };

  const options = {
    //  check here for data-mention attributes and replace with global variables
    replace: ({ name, attribs, children, prev, ...rest }) => {
      if (name === "span" && attribs?.class == "mention") {
        const variableName = get(attribs, "data-mention", "").replace("@variable:", "");
        return <>{gv(variableName)}</>;
        // return <MentionSWR attribs={attribs} />;
      }
      // anchor tag
      if (name === "a" && attribs?.classes && attribs?.classes?.includes("button")) {
        // attribs.class.name
        return (
          <Button
            type={attribs.type}
            link={attribs.href}
            size="lg"
            arrow={attribs.arrow}
            label={domToReact(children, options)}
          />
        );
      }
      if (name === "a" && attribs.href) {
        // if starts with http
        return (
          <NextLink href={attribs.href || "#"} blank={attribs?.target || false}>
            {domToReact(children, options)}
          </NextLink>
        );
      }

      if (name === "h2" && ToC) {
        const extractedTextedFromH2 = deepFindTextFromTag(children, ["data"]);
        tocArray.push(extractedTextedFromH2);
        return (
          <h2 className="toc-header" style={parseStyleString(attribs?.style)} id={`toc_title_${tocArray.length}`}>
            {domToReact(children, options)}
          </h2>
        );
      }

      // support for youtube embedding
      if (
        name === "oembed" &&
        attribs.url &&
        (attribs.url.includes("youtube.com") || attribs.url.includes("youtu.be"))
      ) {
        let src = "";

        if (attribs.url.includes("youtube.com")) {
          src = "https://www.youtube.com/embed/" + attribs.url.split("v=")[1];
        } else if (attribs.url.includes("youtu.be")) {
          src = "https://www.youtube.com/embed/" + attribs.url.replace("https://youtu.be/", "");
        }

        if (src.length === 0) {
          return null;
        }

        return (
          <div className="relative w-full" style={{ paddingBottom: "56%" }}>
            <iframe
              className="absolute left-0 top-0 h-full w-full"
              width="100%"
              height="100%"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen=""
              src={src}
            />
          </div>
        );
      }

      // support for imgs
      if (name === "img" && attribs.src) {
        const { src, ...other } = attribs;
        const srcLink = src.indexOf("http") == -1 ? process.env.NEXT_PUBLIC_API_STRAPI + src : src;
        // if (isAmp) {
        //   return <amp-img src={srcLink} {...other} />;
        // }
        return <NaturalImage {...other} src={srcLink} />;
      }
      if (name === "span" && attribs?.class?.includes("vertical-slider")) {
        const sliderArray = domToReact(children, options)
          .match(/[^{}]*(?=\})/g)[0]
          .replace(/ /g, "")
          .split("/");
        const [width, setWidth] = useState(0);
        const ref = useRef(null);
        const [activeIndex, setActiveIndex] = useState(0);

        useEffect(() => {
          setWidth(ref.current.clientWidth);
          const interval = setInterval(() => {
            if (activeIndex < sliderArray.length - 1) {
              setActiveIndex(activeIndex + 1);
            } else {
              setActiveIndex(0);
            }
            setWidth(ref.current.clientWidth);
          }, 2000);
          return () => {
            clearInterval(interval);
          };
        }, [activeIndex]);

        return (
          <div className={` ${styles.cssVerticalSlider}`}>
            <div
              className={`${styles.cssVerticalSliderInner}`}
              style={{ width: width + `px`, transform: `translateY(-${(activeIndex * 100) / sliderArray.length}%)` }}
            >
              {sliderArray.map((item, i) => (
                <div ref={i === activeIndex ? ref : null} key={item}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        );
      }
    },
  };

  const parsedHtml = parse(formattedText, options);
  if (ToC && tocArray.length > 0 && !isEqual(tocArray, tableOfContents)) {
    setTableOfContents(tocArray);
  } else if (ToC && tocArray.length === 0) {
    setTableOfContents([]);
  }
  return parsedHtml;
}

function ComponentHasNoData() {
  // new relic here
  return null;
}

const HTMLRender = ({ data = false, className = "", ToC = false }) => {
  if (data) {
    return <span className={`rich-text ${className}`}>{parseWithHTML(data, ToC)}</span>;
  }
  return ComponentHasNoData();
};

export default HTMLRender;
