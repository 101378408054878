import styles from './CloseButton.module.css'
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';

const CloseButton = ({ closeText = false, onClick = false }) => {
  return (
    <div className={styles.CloseButtonBody} onClick={onClick}>
      {closeText && (
        <span className={`${styles.CloseText} rtl:hidden`}>{closeText}</span>
      )}
      <button type="button" className={styles.CloseButton}>
        <span className={`${styles.IconCross} text-white`}>
          <GrFormClose />
        </span>
      </button>
      {closeText && (
        <span className={`${styles.CloseText} ltr:hidden`}>{closeText}</span>
      )}
    </div>
  );
};

export default CloseButton;