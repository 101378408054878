/* eslint-disable react-hooks/exhaustive-deps */
import { setCookie } from "cookies-next";
import { useCallback, useEffect, useState } from "react";

import find from "lodash/find";
import get from "lodash/get";
import useLocale from "@lib/store/hooks/useLocale";
import { useRouter } from "next/router";
import { useStore } from "@lib/store";
import useSessionStorage from "./useSessionStorage";

/**
 * useActiveProductType : Change the product type
 * @param {string} defaultValue
 * @returns {string, function} value, handleSwitch
 */

export default function useSetProductType() {
  const router = useRouter();
  const locale = useLocale();
  const currentPageProductType = useStore((store) => store.product_type);
  const setCurrentProductType = useStore(store=>store.setCurrentProductType);
  const [sessionProductType, setSessionProductType] = useSessionStorage({
    key: `capex:product_type:${locale}`,
  });
  const productTypes = useStore(store=>store.productTypes);

  const changeProductType = useCallback(
    (productType, redirect_to_different_license = false) => {
      setSessionProductType(productType);
      setCurrentProductType(productType);

      if (productTypes?.data) {
        const product = productTypes?.filter((el) => el?.attributes?.product_type === productType);
        if (!product || product.length === 0) return;
        if (redirect_to_different_license) {
          setCookie("manually_changed_license", true);
          router.push(get(product, "[0].attributes.redirect_to_different_license", ""));
        } else {
          router.push(`/${locale}/${get(product, "[0].attributes.product_homepage.data.attributes.slug", "")}`);
        }
      }
    },
    [productTypes, locale]
  );
  useEffect(() => {
    if (currentPageProductType && currentPageProductType !== sessionProductType) {
      console.log('currentPageProductType',currentPageProductType)
      setSessionProductType(currentPageProductType);
      setCurrentProductType(currentPageProductType);
    }

    if (!currentPageProductType && !sessionProductType) {
      setSessionProductType("trade");
      setCurrentProductType('trade');
    }
  }, [router?.asPath]);

  return {
    changeProductType,
  };
}
export function useRegistrationLink(customProduct = false) {
  const productTypes = useStore((store) => store.productTypes);
  const currentProductTypeKey = useStore((store) => store.currentProductTypeKey);
  const [registrationLink, setRegistrationLink] = useState("/registration");

  useEffect(() => {
    let productType = customProduct ? customProduct : currentProductTypeKey;
    
    // Find the current product type
    const currentProductType = find(productTypes, ["attributes.product_type", productType]);
    
    // Set the registration link
    const newRegistrationLink = currentProductType?.attributes?.registration_link || "/registration";
    setRegistrationLink(newRegistrationLink);
  }, [currentProductTypeKey, customProduct, productTypes]);

  return registrationLink;
}

export function useLoginLink(customProduct = false) {
  const productTypes = useStore((store) => store.productTypes);
  const currentProductTypeKey = useStore((store) => store.currentProductTypeKey);
  const [loginLink, setLoginLink] = useState("/login");

  useEffect(() => {
    let productType = customProduct ? customProduct : currentProductTypeKey;

    // The reason of not using the currentProductType from store is because there are places where we overwrite the login link
    const currentProductType = find(productTypes, ["attributes.product_type", productType]);

    // Set the login link
    const newLoginLink = currentProductType?.attributes?.login_link || "/login";
    setLoginLink(newLoginLink);
  }, [currentProductTypeKey, customProduct, productTypes]);

  return loginLink;
}
