import Image from 'next/image';
import { useState } from 'react';

const NaturalImage = ({ src, ...props }) => {
    const { class: className, ...otherProps } = props;
    const [naturalSize, setNaturalSize] = useState({
        width: 1,
        height: 1,
        strapiWidth: otherProps?.style?.includes("px")
            ? parseInt(
                otherProps?.style
                    ?.replace("width:", "")
                    ?.replace("px;", "") || 1
            )
            : parseFloat(
                otherProps?.style?.replace("width:", "").replace("%", "")
            ) / 100 || "",
    });


    if (otherProps?.style?.includes("px") && otherProps?.style?.includes("width")) {
        return (
            <div style={{ position: 'relative' }}>
                <Image
                    src={src}
                    className={className}
                    //   {...props}
                    unoptimized /* VERY IMPORTANT */
                    onLoadingComplete={(img) => {
                        const size = img.naturalHeight / img.naturalWidth;
                        if (naturalSize.strapiWidth === 1) {
                            setNaturalSize({
                                width: img.naturalWidth,
                                height: img.naturalHeight,
                            })
                        } else {
                            setNaturalSize({
                                width: naturalSize.strapiWidth,
                                height: parseInt(naturalSize.strapiWidth * size),
                            })
                        }
                    }}
                    width={parseInt(naturalSize.width)}
                    height={parseInt(naturalSize.height)}
                    alt={otherProps?.alt || undefined}
                />
            </div>

        );
    }
    if (otherProps?.style?.includes("%") && otherProps?.style?.includes("width")) {
        return (
            <div style={{ position: 'relative' }}>
                <Image
                    src={src}
                    className={className}
                    //   {...props}
                    unoptimized /* VERY IMPORTANT */
                    onLoadingComplete={(img) => {
                        if (naturalSize.strapiWidth === "") {
                            setNaturalSize({
                                width: img?.naturalWidth,
                                height: img?.naturalHeight,
                            })
                        } else {
                            setNaturalSize({
                                width: naturalSize.strapiWidth * img?.naturalWidth,
                                height: naturalSize.strapiWidth * img?.naturalHeight,
                            })
                        }
                    }}
                    width={naturalSize?.width}
                    height={naturalSize?.height}
                    alt={otherProps?.alt || undefined}
                />
            </div>

        );
    }
    return (
        <div style={{ position: 'relative' }}>
            <Image
                src={src}
                className={className}
                //   {...props}
                unoptimized /* VERY IMPORTANT */
                onLoadingComplete={(img) => {
                    setNaturalSize({
                        width: img?.naturalWidth,
                        height: img?.naturalHeight,
                    })
                }}
                width={naturalSize?.width}
                height={naturalSize?.height}
                alt={otherProps?.alt || undefined}
            />
        </div>

    );

}
export default NaturalImage;