import HTMLRender from '@components/core/HTMLRender';
import Button from '../Button';
import style from './Modal.module.css';
import NextImage from '../Image';
import CloseButton from '../CloseButton';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';

const AnalystModalTop = ({ name = '', title = '', image = null }) => {
  return (
    <div className={'relative m-0 flex items-center justify-between gap-0 bg-secondary p-0 align-middle'}>
      <div className='text-white'>
        <h2 className='font-bold px-lg'>{name}</h2>
        {/* <h4>{title}</h4> */}
      </div>
      {image && (
        <div className="relative max-w-[25%] ltr:mr-[-1px] rtl:ml-[1px]">
          <NextImage
            src={image}
            height={image?.data?.attributes?.height}
            width={image?.data?.attributes?.width}
          />
        </div>
      )}
    </div>
  );
};
const ModalAnalystTemplate = ({ context, id, innerProps }) => {
  const { gv } = useGlobalVariable();
  return (
    <div className="bg-white rounded-xl mt-6  overflow-hidden">
      <CloseButton
        closeText={gv('close')}
        onClick={() => context.closeModal(id)}
      />
      <AnalystModalTop
        image={innerProps?.attributes?.image_variant_a}
        name={innerProps?.attributes?.name}
        title={innerProps?.attributes?.title}
      />
      <div
        className={style.containerAuthor}
        style={{
          padding: '2rem',
          lineHeight: '1.75',
        }}
      >
        <HTMLRender
          data={
            innerProps?.attributes?.content.length > 0 &&
            innerProps.attributes?.content
          }
        />
      </div>
    </div>
  );
}

export default ModalAnalystTemplate;