import { useModals } from "@mantine/modals";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function useModalHook() {
  const modal = useModals();
  const router = useRouter();
  const [previousPath, setPreviousPath] = useState(null);
  /**
   * @param {string} key_id Modal Key_id linked to the ApiProvider Component
   * @param {string} template Html Template String
   * */
  const openCloseAll = (key_id, template) => {
    modal.closeAll();
    if (key_id === undefined || template === undefined) return;
    modal.openContextModal(key_id || "defaultModal", template);
  };

  useEffect(() => {
    // we check if we have different paths.
    // keep in mind, modals trigger router.asPath to rerender.
    if (previousPath !== router?.asPath) {
      if (previousPath) {
        // if we have an existing previousPath which is different with the current path,
        // we close all modals.
        modal.closeAll();
        setPreviousPath(router?.asPath);
      } else {
        // if there are no previousPaths, set the previous path.
        setPreviousPath(router?.asPath);
      }
    }
  }, [router?.asPath]);

  /**
   * @param {string} key_id Modal Key_id linked to the ApiProvider Component
   * @param {string} template Html Template String
   * */
  const open = (key_id, template) => {
    if (key_id === undefined || template === undefined) return;
    modal.openContextModal(key_id || "defaultModal", template);
  };

  return { open, openCloseAll };
}
