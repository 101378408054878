import useLocale from "@lib/store/hooks/useLocale";
import { useRef } from "react";
import useSWR from "swr";

import _swrConfig from "./swr.config";
const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function useSwrHook(api, { query = {}, customConfig = {} }) {
  const locale = useLocale();
  if (api === undefined) {
    return {
      data: undefined,
      isValidating: false,
      error: true,
    };
  }
  const fnConfig = _swrConfig(api);
  if (typeof fnConfig != "function") return;
  const config = fnConfig({ locale, ...query }, customConfig);
  const { url, ...rest } = config;
  // assign commit sha to swr url
  const buildId = process.env.NEXT_PUBLIC_BUILD_ID;
  const urlWithBuildId = buildId ? `${url}&build_id=${buildId}` : url;

  return useSWR(urlWithBuildId, fetcher, {
    ...rest,
  });
}

export function useSwrHookWithPreviousData(api, { query, customConfig }) {
  const fnConfig = _swrConfig(api);
  const config = fnConfig(query, customConfig);
  const { url, ...rest } = config;
  const currentDataRef = useRef();
  const previousDataRef = useRef(undefined);
  // assign commit sha to swr url
  const buildId = process.env.NEXT_PUBLIC_BUILD_ID;
  const urlWithBuildId = buildId ? `${url}&build_id=${buildId}` : url;

  const { data, error } = useSWR(urlWithBuildId, fetcher, {
    ...rest,
  });
  previousDataRef.current = currentDataRef.current;
  currentDataRef.current = data;

  return {
    loading: data === undefined,
    error,
    data: data || currentDataRef.current,
    previousData: previousDataRef.current || currentDataRef.current,
  };
}
