import { getStrapiMedia } from "@utils/media";
import get from "lodash/get";
import isObject from "lodash/isObject";

export default function useSrc(props) {
  // support for direct strapi object (without attributes / data)
  if (props.src && props?.src?.url) {
    const src = getStrapiMedia(props?.src?.url);
    const priority = get(props, "priority", false);
    return { src, priority };
  }

  const src =
    get(props, "src.attributes.url", false) || get(props, "src.data.attributes.url", false) || props?.src || false;
  const updatedAt =
    get(props, "attributes.updatedAt", false) ||
    get(props, "src.attributes.updatedAt", false) ||
    get(props, "src.data.attributes.updatedAt", false) ||
    get(props, "src.data.0.attributes.updatedAt", false);
  const priority =
    get(props, "src.attributes.priority", false) ||
    get(props, "src.data.attributes.priority", false) ||
    get(props, "src.data.0.attributes.priority", false);

  if (!src || (isObject(src) && !src.data)) return null;

  return {
    src: getStrapiMedia(src),
    updatedAt,
    priority,
  };
}
