import HTMLRender from '@components/core/HTMLRender';
import Button from '../Button';

const ModalMantine = ({context}) => {
    const {modals} = context;
    const props = modals && modals.length > 0 ? modals[0].props : undefined;
    if(props === undefined) return null;

    //inside props, you can access the property you pass inside the custom useModalHook
    return (
      <>
        <HTMLRender
          data={
            props.content
          }
        />
        <Button mt="md" onClick={() => props.context.closeModal(id)}>
          Close modal
        </Button>
      </>
    );
}

export default ModalMantine;